import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { NgxMaskModule } from "ngx-mask";
import { NgxPermissionsGuard, NgxPermissionsModule } from "ngx-permissions";
import { SessionGuard } from "../core/session/session.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "/login",
    pathMatch: "full",
  },

  // Boot
  {
    path: "loading",
    loadChildren: () =>
      import("./loading/loading.module").then((m) => m.LoadingModule),
  },
  {
    path: "error",
    loadChildren: () =>
      import("./error/error.module").then((m) => m.ErrorModule),
  },
  {
    path: "login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "logout",
    loadChildren: () =>
      import("./logout/logout.module").then((m) => m.LogoutModule),
  },
  {
    path: "access-denied",
    loadChildren: () =>
      import("./access-denied/access-denied.module").then(
        (m) => m.AccessDeniedModule
      ),
  },
  
  // DO NOT ADD ANYTHING ABOVE UNLESS U KNOW WHAT U R DOING
  // DO ADD ANYTHING BELOW THIS LINE

  //move  to home module

  {
    path: "",
    loadChildren: () => import("./home/home.module").then((m) => m.HomeModule),
  },

  {
    path: "**",
    redirectTo: "/error?e=ROUTE_NOT_FOUND",
  },
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes, { useHash: false, enableTracing: false }), // UseHash should be false, for msal to work
    NgxPermissionsModule.forChild(),
    NgxMaskModule.forRoot(),
  ],
  providers: [NgxPermissionsGuard, SessionGuard],
  exports: [RouterModule],
})
export class PagesModule {}
