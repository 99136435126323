import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PagesModule } from './pages/pages.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoggerModule } from 'ngx-logger';
import { SessionService } from './core/session/session.service';
import { sessionProviderFactory } from './core/session/session.module';
import { InjectorResolver } from './core/injector/injector.service';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TopnavModule } from './shared/topnav/topnav.module';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { BlockUIModule } from 'ng-block-ui';
import { DashboardLayoutModule } from '@syncfusion/ej2-angular-layouts';

@NgModule({
  declarations: [
    AppComponent],
  imports: [
    PagesModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxPermissionsModule.forRoot(),
    LoggerModule.forRoot(null),
    BlockUIModule.forRoot(),
    TopnavModule,
    DashboardLayoutModule
  ],
  providers: [
    SessionService,
    {
      provide: APP_INITIALIZER,
      useFactory: sessionProviderFactory,
      deps: [InjectorResolver, SessionService],
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
