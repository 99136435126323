import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";

import { SessionService } from "src/app/core/session/session.service";
import { AppLocalStorage } from "src/app/utility/local-storage.util";
import { SnakbarService } from "src/app/shared/common/snakbar/snakbar.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private sessionService: SessionService,
    private router: Router,
    private snakbarService: SnakbarService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const me = this;

    return next.handle(request).pipe(
      catchError((err) => {
        // console.log(err);
        if (err.status == 500) {

          AppLocalStorage.clear("GOOGLE_SESSION", "GOOGLE_SESSION");

          if (!AppLocalStorage.get("GOOGLE_SESSION", "GOOGLE_SESSION")) {
            let apiTimeOut;
            if (apiTimeOut) {
              clearTimeout(apiTimeOut);
            }
            apiTimeOut = setTimeout(() => {

          me.snakbarService.showToast(
            document.getElementById("appErrorToast"),
            {
              title: "Internal Server Error",
              position: {
                X: "Right",
              },
              type: "ERROR",
              showCloseButton: true,
            }
          );
              window.location.href = "/login";
               me.sessionService.redirectToLoginPage();
            }, 1000);
          }

        } else if (err.status == 403) {
          // me.snackBarService.openSnackBar("your session has expired please login again", 3000);
          // me.router.navigate(['logout']);

          AppLocalStorage.clear("GOOGLE_SESSION", "GOOGLE_SESSION");

          if (!AppLocalStorage.get("GOOGLE_SESSION", "GOOGLE_SESSION")) {
            let apiTimeOut;
            if (apiTimeOut) {
              clearTimeout(apiTimeOut);
            }
            apiTimeOut = setTimeout(() => {
              me.snakbarService.showToast(
                document.getElementById("appErrorToast"),
                {
                  title: "your session has expired please login again",
                  type: "ERROR",
                  showCloseButton: true,
                }
              );
               window.location.href = '/login'
               me.sessionService.redirectToLoginPage();
            }, 1000);
          }

          // me.sessionService.clear().subscribe(() => {

          // });

          // let apiTimeOut;
          // if (apiTimeOut) {
          //     clearTimeout(apiTimeOut);
          // }
          // apiTimeOut = setTimeout(() => {
          //     me.sessionService.clear().subscribe(() => {

          //         me.snackBarService.openSnackBar("your session has expired please login again", 3000);

          //         me.sessionService.redirectToLoginPage();

          //        });
          // }, 1000);
        }
        const error = err;
        return throwError(error);
      })
    );
  }
}
