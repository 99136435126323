import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { Store } from "src/app/core/store/store";
import { environment } from "src/environments/environment";
// import { ListPayload } from './license-management.model';
import {
  createNewClientLoadedState,
  createNewClientLoadingErrorState,
  createNewClientLoadingState,
  getClientListLoadedState,
  getClientListLoadingErrorState,
  getClientListLoadingState,
  getDeploymentListLoadedState,
  getDeploymentListLoadingErrorState,
  getDeploymentListLoadingState,
  getPrimaryAdminListLoadedState,
  getPrimaryAdminListLoadingErrorState,
  getPrimaryAdminListLoadingState,
  updateLicenseLoadedState,
  updateLicenseLoadingErrorState,
  updateLicenseLoadingState,
} from "./license-management.state";
import {
  createLicenseLoadingErrorState,
  createLicenseLoadingState,
  createLicenseLoadedState,
} from "./license-management.state";

import {
  logoutLoadingState,
  logoutLoadedState,
  logoutLoadingErrorState,
} from "./license-management.state";
@Injectable({
  providedIn: "root",
})
export class LicenseManagementService extends Store.AbstractService {
  getDeploymentList(payload): Observable<Store.State> {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new getDeploymentListLoadingState());
    }, 0);

    const path = environment.api.deploymentDashboard.licenseList.endpoint;

    me.controller
      .post(path, payload, environment.api.core.defaultBase, {
        Authorization: true,
      })
      .subscribe(
        (data: any) => {
          output.next(new getDeploymentListLoadedState(data));
          output.complete();
        },
        (e: any) => {
          output.error(new getDeploymentListLoadingErrorState(e));
          output.complete();
        }
      );

    return output;
  }

  getClientList(payload): Observable<Store.State> {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new getClientListLoadingState());
    }, 0);

    const path = environment.api.deploymentDashboard.clientList.endpoint;

    me.controller
      .post(path, payload, environment.api.core.defaultBase, {
        Authorization: true,
      })
      .subscribe(
        (data: any) => {
          output.next(new getClientListLoadedState(data));
          output.complete();
        },
        (e: any) => {
          output.error(new getClientListLoadingErrorState(e));
          output.complete();
        }
      );

    return output;
  }

  createLicense(payload): Observable<Store.State> {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new createLicenseLoadingState());
    }, 0);

    const path = environment.api.deploymentDashboard.createLicense.endpoint;

    me.controller
      .post(path, payload, environment.api.core.base, { Authorization: true })
      .subscribe(
        (data: any) => {
          output.next(new createLicenseLoadedState(data));
          output.complete();
        },
        (e: any) => {
          output.error(new createLicenseLoadingErrorState(e));
          output.complete();
        }
      );

    return output;
  }

  logout(): Observable<Store.State> {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new logoutLoadingState());
    }, 0);

    const path = environment.api.deploymentDashboard.activateLicense.endpoint;
    me.controller.get(path, null, environment.api.core.mockBase).subscribe(
      (data: any) => {
        output.next(new logoutLoadedState(data));
        output.complete();
      },
      (e: any) => {
        output.error(new logoutLoadingErrorState(e));
        output.complete();
      }
    );

    return output;
  }

  addNewClient(payload): Observable<Store.State> {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new createNewClientLoadingState());
    }, 0);

    const path =
      environment.api.deploymentDashboard.createLicenseWithNewClient.endpoint;

    me.controller
      .post(path, payload, environment.api.core.defaultBase, {
        Authorization: true,
      })
      .subscribe(
        (data: any) => {
          output.next(new createNewClientLoadedState(data));
          output.complete();
        },
        (e: any) => {
          output.error(new createNewClientLoadingErrorState(e));
          output.complete();
        }
      );

    return output;
  }

  getAllPrimaryAdmins(id): Observable<Store.State> {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new getPrimaryAdminListLoadingState());
    }, 0);

    const path = me.controller.replaceVariables(
      environment.api.deploymentDashboard.getAllPrimaryAdmins.endpoint,
      { clientId: id }
    );

    me.controller
      .get(path, null, environment.api.core.defaultBase, {
        Authorization: true,
      })
      .subscribe(
        (data: any) => {
          output.next(new getPrimaryAdminListLoadedState(data));
          output.complete();
        },
        (e: any) => {
          output.error(new getPrimaryAdminListLoadingErrorState(e));
          output.complete();
        }
      );

    return output;
  }


  updateLicense(payload): Observable<Store.State> {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new updateLicenseLoadingState());
    }, 0);

    const path = environment.api.deploymentDashboard.updateLicense.endpoint;

    me.controller
      .post(path, payload, environment.api.core.base, { Authorization: true })
      .subscribe(
        (data: any) => {
          output.next(new updateLicenseLoadedState(data));
          output.complete();
        },
        (e: any) => {
          output.error(new updateLicenseLoadingErrorState(e));
          output.complete();
        }
      );

    return output;
  }
}
