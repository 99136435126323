import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertComponent } from './alert.component';
import { DialogModule } from '@syncfusion/ej2-angular-popups';



@NgModule({
  declarations: [
    AlertComponent
  ],
  imports: [
    CommonModule,
    DialogModule
  ],
  exports: [AlertComponent]
})
export class AlertModule { }
