<ejs-dialog *ngIf="showModal" [showCloseIcon]='true' id="logoutModal" #logoutModal
    [position]="{ X: 'center', Y: 'center' }" width='480px' height="350px" [animationSettings]="animationSettings"
    (close)="closeDialog()" [isModal]="true">
    <ng-template #header>
        {{headerText}}
    </ng-template>

    <div class="logout-license-main-container">
        <p class="montserrat-Bold bold-color font-size-16">Are you sure you want to Logout ? </p>
        <div class="footer-buttons btn-margin" #footerButtons fxLayout="row" fxLayoutAlign="end space-around">

            <button ejs-button #cancelbtn cssClass="e-outline" [isPrimary]="false" type="button" fxLayout="column"
                (click)="closeDialog()" class="montserrat-Bold mr-20 secondary-btn">
                Cancel
            </button>
            <button ejs-button [isPrimary]="true" #logoutbtn fxLayout="row" class="primary-btn"
                [disabled]="processing" cssclass="e-control e-btn" type="button" style="text-transform:initial;"
                (click)="logout()">
                <span class="d-flex mr-5">
                    Logout
                    <span *ngIf="processing" class="ml-15">
                        <app-spinner id="logout-license" width="16"></app-spinner>
                    </span>
                </span>
            </button>
        </div>
    </div>
</ejs-dialog>

<div id="toast" #toast> </div>