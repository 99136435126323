import { Location } from "@angular/common";
import { Injectable } from "@angular/core";
import { NavigationEnd, Router, RouterEvent } from "@angular/router";
import * as moment from "moment";
import { NgxPermissionsObject, NgxPermissionsService } from "ngx-permissions";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { CustomUtilityService } from "src/app/shared/common/services/custom-utility.service";
import { SnakbarService } from "src/app/shared/common/snakbar/snakbar.service";
import { Subscriptions } from "src/app/utility/common.model";
import { IDGenerator } from "src/app/utility/id-generator.util";
import { AppLocalStorage } from "src/app/utility/local-storage.util";
import { environment } from "src/environments/environment";
import { AppError } from "../error/error.model";
import { Store } from "../store/store";
import {
  AuthTokenResponse,
  LoginCredentials,
  LogoutResponse,
  Session,
  SessionAccount,
  SessionAccountResponse,
  signUpCredentials,
} from "./session.model";
import {
  SessionCreatingErrorState,
  SessionCreatingState,
  SessionDestroyedState,
  SessionDestroyingState,
  SessionDestroyingErrorState,
  LogoutLoadingState,
  LogoutLoadedState,
  LogoutErrorState,
  GoogleSignInLoadingState,
  GoogleSignInLoadedState,
  GoogleSignInErrorState,
  FacebookSignInLoadingState,
  FacebookSignInLoadedState,
  FacebookSignInErrorState,
} from "./session.state";

//import { MyNotificationPayload } from 'src/app/pages/notifications/service/my-notification.model';

@Injectable({
  providedIn: "root",
})
export class SessionService extends Store.AbstractService {
  private static RESTRICTED_REDIRECT_URLS: string[] = [
    "/loading",
    "/login",
    "/logout",
    "/profile-settings",
  ];

  // sessionValidityTimer: NodeJS.Timer;
  sessionValidityTimer: any;

  private redirectURL: string;
  private redirectToken: string;
  session: Session;
  account: SessionAccount;
  tabSessionId: string;
  private logoPath = new BehaviorSubject<string>("");
  readonly logoPath$ = this.logoPath.asObservable();

  private bannerPath = new BehaviorSubject<string>("");
  readonly bannerPath$ = this.bannerPath.asObservable();

  private fullName = new BehaviorSubject<string>("");
  readonly fullName$ = this.fullName.asObservable();

  private notificationEnable = new BehaviorSubject<boolean>(false);
  readonly notificationEnable$ = this.notificationEnable.asObservable();

  private subscriptions: Subscriptions = {
    sessionValidate: null,
  };
  public temp: boolean = false;

  constructor(
    private router: Router,
    private permissionsService: NgxPermissionsService,
    private location: Location,
    private snakbarService: SnakbarService
  ) {
    super();
    const me = this;

    me.tabSessionId = IDGenerator.newId();

    me.router.events.subscribe((re: RouterEvent) => {
      me.onRouterEvent(re);
    });

    window["getAccessToken"] = () => {
      return me.session.token;
    };
  }

  init() {
    const me = this;
    me.setRedirectURL();

    return new Promise<void>((resolve, reject) => {
      me.initSession(resolve, reject);
    });
  }

  redirectToLoginPage() {
    const me = this;

    me.setRedirectURL();
    me.router.navigate(["/login"]);
  }

  redirectToHomePage() {
    const me = this;

    let redirectTo = "/license-dashboard";
    // if (me.account.roles[0].code == "PRO_USER") {
    //   redirectTo = "/dashboard";
    // }

    // if (me.redirectToken && me.redirectURL) {
    //   redirectTo = me.redirectURL;
    //   me.redirectToken = null;
    // }

    me.router.navigate([redirectTo]);
  }

  private setRedirectURL(url?: string) {
    const me = this;
    const sessionInfo = AppLocalStorage.get("GOOGLE_SESSION", "GOOGLE_SESSION");
    let redirectURL: string = url;
    if (sessionInfo?.token) {
      redirectURL = url || me.location.path();
    }

    me.redirectToken = IDGenerator.newId();

    if (SessionService.RESTRICTED_REDIRECT_URLS.indexOf(redirectURL) !== -1) {
      redirectURL = null;
    }

    me.redirectURL = redirectURL;
  }

  private isSSORequest(): boolean {
    return window.location.hash.startsWith("#id_token");
  }

  private isFirmDetailsPage(): boolean {
    return SessionService.RESTRICTED_REDIRECT_URLS.indexOf("/firm/info") !== -1;
  }

  private isSignUpPage(): boolean {
    return (
      SessionService.RESTRICTED_REDIRECT_URLS.indexOf("/signup/register") !== -1
    );
  }

  private isForgotPasswordPage(): boolean {
    return (
      SessionService.RESTRICTED_REDIRECT_URLS.indexOf(
        "/signup/forgot-password"
      ) !== -1
    );
  }

  private isProfileSettingsPage(): boolean {
    return (
      SessionService.RESTRICTED_REDIRECT_URLS.indexOf("/profile-settings") !==
      -1
    );
  }

  // public login(credentials: LoginCredentials): Observable<Store.State> {
  //   const me = this;
  //   const output = new Subject<Store.State>();

  //   setTimeout(() => {
  //     output.next(new SessionCreatingState());
  //   }, 0);

  //   me.controller
  //     .post<AuthTokenResponse>(
  //       environment.api.session.login.endpoint,
  //       credentials
  //     )
  //     .subscribe(
  //       (data: AuthTokenResponse) => {
  //         console.log("data called",data);
  //         if (data) {

  //           me.session = {
  //             token: data.data.appToken,
  //             id: IDGenerator.newId(),
  //             expiry: moment().add(1, "day").toDate(),
  //           };

  //           AppLocalStorage.set("SESSION", "SESSION", me.session);

  //           if (data.status == "SIGNED_IN_SUCCESSFULLY") {
  //             me.snakbarService.showToast(document.getElementById("toast"), {
  //               title: "Success",
  //               content: "Logged In Successfully",
  //               position: {
  //                 X: "Right",
  //               },
  //               type: "SUCCESS",
  //               showCloseButton: true,
  //             });
  //           }

  //           // Uncomment After ME API Implementation
  //           // me.loadAccount().subscribe(() => {
  //           //   me.redirectToHomePage();

  //           //   if (data.status == "LOGIN_SUCCESSFUL") {
  //           //     //me.snackbar.openSnackBar('Signed in successfully', 3000);
  //           //   }
  //           //   me.temp = true;
  //           //   me.startSessionValidation();
  //           //   output.complete();
  //           // });

  //           // DELETE THIS

  //           me.redirectToHomePage();
  //           me.temp = true;
  //             me.startSessionValidation();
  //             output.complete();
  //         } else {
  //           setTimeout(() => {
  //             output.error(
  //               new SessionCreatingErrorState(new AppError("No Token Received"))
  //             );
  //             output.complete();
  //           }, 0);
  //         }
  //       },
  //       (e: Error) => {
  //         setTimeout(() => {
  //           output.error(new SessionCreatingErrorState(AppError.fromError(e)));
  //           output.complete();
  //         }, 0);
  //       }
  //     );

  //   return output;
  // }

  public logout(): Observable<Store.State> {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new LogoutLoadingState());
    }, 0);

    me.controller
      .get(environment.api.session.logout.endpoint, null, undefined, {
        Authorization: true,
      })
      .subscribe(
        (data: LogoutResponse) => {
          if (data) {
            //todo check and uncomment
              me.clear();
            output.next(new LogoutLoadedState(data));
              window.localStorage.clear();
            // console.log("window.sessionStorage.clear ");
            me.router.navigate(["/login"]);
            output.complete();
          }
        },
        (e: Error) => {
          setTimeout(() => {
            output.error(new LogoutErrorState(AppError.fromError(e)));
            output.complete();
          }, 0);
        }
      );
    return output;
  }

  public googleLogin(requestBody: any): Observable<Store.State> {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new GoogleSignInLoadingState());
    }, 0);

    me.controller
      .post(
        environment.api.session.googleSignIn.endpoint,
        requestBody,
        undefined,
        { Authorization: true }
      )
      .subscribe(
        (data: any) => {
          if (data) {
            me.session = {
              token: data?.data,
              id: IDGenerator.newId(),
              expiry: moment().add(1, "day").toDate(),
            };

            AppLocalStorage.set("GOOGLE_SESSION", "GOOGLE_SESSION", me.session);

            // Uncomment After ME API Implementation
            // me.loadAccount().subscribe(() => {
            //   me.redirectToHomePage();

            //   if (data.status == "LOGIN_SUCCESSFUL") {
            //     //me.snackbar.openSnackBar('Signed in successfully', 3000);
            //   }
            //   me.temp = true;
            //   me.startSessionValidation();
            //   output.complete();
            // });

            // DELETE THIS

            me.redirectToHomePage();
            me.temp = true;
            me.startSessionValidation();
            //output.complete();
            output.next(new GoogleSignInLoadedState(data));
            output.complete();
          }
        },
        (e: Error) => {
          setTimeout(() => {
            output.error(new GoogleSignInErrorState(AppError.fromError(e)));
            output.complete();
          }, 0);
        }
      );
    return output;
  }

  public facebookLogin(requestBody: any): Observable<Store.State> {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new FacebookSignInLoadingState());
    }, 0);

    me.controller
      .post(
        environment.api.session.facebookSignIn.endpoint,
        requestBody,
        undefined,
        { Authorization: true }
      )
      .subscribe(
        (data: any) => {
          if (data) {
            me.session = {
              token: data.data.appToken,
              id: IDGenerator.newId(),
              expiry: moment().add(1, "day").toDate(),
            };

            // AppLocalStorage.set("SESSION", "SESSION", me.session);
            // Uncomment After ME API Implementation
            // me.loadAccount().subscribe(() => {
            //   me.redirectToHomePage();

            //   if (data.status == "LOGIN_SUCCESSFUL") {
            //     //me.snackbar.openSnackBar('Signed in successfully', 3000);
            //   }
            //   me.temp = true;
            //   me.startSessionValidation();
            //   output.complete();
            // });

            // DELETE THIS

            me.redirectToHomePage();
            me.temp = true;
            me.startSessionValidation();
            //output.complete();
            output.next(new FacebookSignInLoadedState(data));
            output.complete();
          }
        },
        (e: Error) => {
          setTimeout(() => {
            output.error(new FacebookSignInErrorState(AppError.fromError(e)));
            output.complete();
          }, 0);
        }
      );
    return output;
  }

  public setLogoPath(logoPathURL) {
    const me = this;
    me.logoPath.next(logoPathURL);
  }

  public setBannerPath(bannerPathURL) {
    const me = this;
    me.bannerPath.next(bannerPathURL);
  }

  public signupLogin(appToken: string) {
    const me = this;
    if (appToken) {
      me.session = {
        token: appToken,
        id: IDGenerator.newId(),
        expiry: moment().add(1, "day").toDate(),
      };

      // AppLocalStorage.set("SESSION", "SESSION", me.session);

      // Uncomment After ME API Implementation

      // me.loadAccount().subscribe(() => {
      //   me.redirectToHomePage();
      //   me.startSessionValidation();
      // });

      // DELETE THIS
      me.redirectToHomePage();
      me.startSessionValidation();
    }
  }

  private loadAccount(): Observable<void> {
    const me = this;
    const output = new Subject<void>();

    const path = environment.api.auth.me.endpoint;

    me.controller.get(path, null, undefined, { Authorization: true }).subscribe(
      (data: SessionAccountResponse) => {
        me.account = data?.data;
        if (me.account?.firms) {
          me.logoPath.next(me.account?.firms[0]?.logoPath);
          me.fullName.next(me.account?.fullName);
          const bannerURL =
            me.account?.firms[0]?.bannerPath &&
            me.account?.firms[0]?.bannerPath != ""
              ? JSON.parse(me.account?.firms[0]?.bannerPath)?.big
              : null;
          me.bannerPath.next(bannerURL);
          //Notification call
          me.myNotification();
        }
        me.refreshPermissions(output);
      },
      (e: any) => {
        output.error(new Error("Failed to Load Account"));
        output.complete();
      }
    );

    return output;
  }

  public refreshAccountInfo() {
    const me = this;

    // Uncomment This
    //me.loadAccount();
  }

  private refreshPermissions(subject: Subject<void>) {
    const me = this;

    if (me.account && me.account.permissions) {
      const permissions = Object.keys(me.account.permissions);
      console.debug("Current User's Permissions", permissions);
      me.permissionsService.loadPermissions(permissions);
      subject.next();
      subject.complete();
    } else {
      subject.error("INSUFFICIENT_PERMISSIONS");
      subject.complete();
    }
  }

  // RolePermission[]
  public getRolePermissionsFor(code: string): any[] {
    const me = this;
    if (me.account && me.account.permissions && me.account.permissions[code]) {
      return Object.values(me.account.permissions[code]);
    }
    return null;
  }

  private startSessionValidation() {
    const me = this;
    me.stopSessionValidation();
    me.sessionValidityTimer = setTimeout(() => {
      // me.validateSession();
    }, 600000);
  }

  private stopSessionValidation() {
    const me = this;
    if (me.sessionValidityTimer) {
      clearTimeout(me.sessionValidityTimer);
    }
  }

  // private validateSession() {
  //   const me = this;

  //   console.debug("Validating Session");

  //   const path = environment.api.auth.validate.endpoint;
  //   me.controller.get(path, null, undefined, { Authorization: true }).subscribe(
  //     (data: any) => {
  //       me.startSessionValidation();
  //     },
  //     (e: any) => {
  //       me.router.navigate(["logout"]);
  //     }
  //   );
  // }

  private myNotification() {
    const me = this;
    const accountId: string[] = [];
    accountId.push(me.account?.id.toString());
  }

  private initSession(resolve: () => void, reject: (reason: AppError) => void) {
    const me = this;

    const session: Session = AppLocalStorage.get(
      "SESSION",
      "SESSION"
    ) as Session;

    if (me.isSessionActive(session)) {
      me.session = session;

      // Uncomment THIS

      // me.loadAccount().subscribe(
      //   () => {
      //     me.validateSession();
      //     resolve();
      //     me.redirectToHomePage();
      //     me.temp = true;
      //   },
      //   (error) => {
      //     reject(new AppError(error));
      //   }
      // );

      //DELETE THIS
      // me.validateSession();
      resolve();
      me.redirectToHomePage();
      me.temp = true;
      return;
    }

    if (me.isSSORequest()) {
      resolve();
      return;
    }

    // TODO: Need to verify this

    if (me.isFirmDetailsPage()) {
      resolve();
      return;
    }

    if (me.isSignUpPage()) {
      resolve();
      return;
    }

    if (me.isForgotPasswordPage()) {
      resolve();
      return;
    }

    if (me.isProfileSettingsPage()) {
      resolve();
      return;
    }

    // Redirect to Login
    //  me.router.navigate(['login']);
    resolve();
  }

  public isActive(): boolean {
    return this.isSessionActive(this.session);
  }

  public clear(): Observable<any> {
    const output = new Subject<any>();
    const me = this;

    // Clear session local storage
    AppLocalStorage.clear("GOOGLE_SESSION", "GOOGLE_SESSION");

    // Stop session validation check
    me.stopSessionValidation();

    //stop notifications.
    // me.notificationService.stopTimer()

    // Reset Session
    me.session = null;

    setTimeout(() => {
      output.next();
    });

    return output;
  }

  public hasPermissionsAll(permissions: string[]): boolean {
    const me = this;
    const sessionPermissions: NgxPermissionsObject =
      me.permissionsService.getPermissions();
    let flag = true;

    for (const permission of permissions) {
      if (!sessionPermissions[permission]) {
        flag = false;
        break;
      }
    }

    return flag;
  }

  private isSessionActive(session: Session): boolean {
    return !!session;
  }

  private onRouterEvent(event: RouterEvent) {
    const me = this;

    if (event instanceof NavigationEnd) {
      switch (event.url) {
        case "/loading":
          me.redirectToken = null;
          break;
      }
    }
  }
}
