import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogoutPopupComponent } from './logout-popup.component';
import { AlertModule } from "src/app/shared/alert/alert.module";
import { DialogModule } from "@syncfusion/ej2-angular-popups";
import { ButtonModule } from "@syncfusion/ej2-angular-buttons";
import { FlexLayoutModule } from "@angular/flex-layout";
import { LoadingModule } from "src/app/pages/loading/loading.module";
import { TextBoxModule } from "@syncfusion/ej2-angular-inputs";
import { SpinnerModule } from "src/app/shared/spinner/spinner.module";
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';

const components = [LogoutPopupComponent];

const googleLoginOptions = {
  scope: "profile email",
  plugin_name: "login", //you can use any name here
  oneTapEnabled: false,
  autoLogin: true,
};

const imports = [
  FlexLayoutModule,
  LoadingModule,
  ButtonModule,
  TextBoxModule,
  AlertModule,
  DialogModule,
  SpinnerModule,
];
@NgModule({
  declarations: [components],
  exports: [components],
  providers: [
    {
      provide: "SocialAuthServiceConfig",
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
"416667867236-i77m3i6r4l79383lpms8c5el61anptjb.apps.googleusercontent.com",
       googleLoginOptions
            ),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider("507269631463656"),
          },
        ],
        onError: (err) => {
          console.error(err);
        },
      } as SocialAuthServiceConfig,
    },
  ],
  imports: [imports, CommonModule],
})
export class LogoutPopupModule { }
