import { Component, ElementRef, OnInit, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { DialogComponent } from "@syncfusion/ej2-angular-popups";
import { AppLocalStorage } from 'src/app/utility/local-storage.util';
import { AlertService } from '../../alert/service/alert.service';
import { AlertComponent } from '../../alert/alert.component';
import { LogoutPopupComponent } from './logout-popup/logout-popup.component';
import { SnakbarService } from '../../common/snakbar/snakbar.service';

@Component({
  selector: 'app-setting-menu-dialog',
  templateUrl: './setting-menu-dialog.component.html',
  styleUrls: ['./setting-menu-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SettingMenuDialogComponent implements OnInit {
  openDialog() {
    // throw new Error("Method not implemented.");
    this.showModal = true;
  }

  showModal = false;

   public confirmWidth: string = '400px';
   public animationSettings: Object = { effect: 'Fade', duration: 400, delay: 0 };
   public position = { X: 'right', Y: 'top' };
   public target: string = '.control-section';


  constructor(
    private alertService : AlertService,
    private snakbarService: SnakbarService
  ) { }
  @ViewChild(LogoutPopupComponent) logoutPopup: LogoutPopupComponent;
  @ViewChild("settingDialog") settingDialog: DialogComponent;
  // @ViewChild("container", { read: ElementRef, static: true })
  @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;
  public targetElement: string = '.control-section';
  userInfo: any;

  ngOnInit(): void {
    this.userInfo = AppLocalStorage.get("UserInfoByGoogle", "userInfo");

  }

  ngAfterViewInit(): void {
    const me = this;
    document.onclick = (args: any) : void => {
      if (args.target.nodeName == 'DIV' || args.target.nodeName == 'rect') {
        if (me.showModal == true) {
          me.showModal = false;
        }
      }
    }
  }


  onOpenPopup() {
    const me = this;
    me.showModal = true;
  }

  closeDialog() {
    this.showModal = false;
  }

  // openLogout(){
  //   const ref = this.alertService.showAlert(this.container, AlertComponent, {
  //     isConfirmation: true,
  //     title: 'Are you sure',
  //     header: 'Logout',
  //     message: ' Do you want to logout from the system?',
  //     position: { X: 'center', Y: 'center' },
  //     height:'350',
  //     width: '480'

  //   });

  //   ref.alertClosed.subscribe((isConfirmed: boolean) => {
  //     if (isConfirmed) {
  //       this.logout();
  //     }
  //   })

  // }
  


  logout() {
    this.logoutPopup.onOpenPopup();
  }

}
