import { statusHandler } from "src/app/utility/status-handler.util";
import { Router } from "@angular/router";
import {
  LogoutLoadingState,
  LogoutLoadedState,
  LogoutErrorState,
} from "./../../core/session/session.state";
import { Store } from "src/app/core/store/store";
import { SessionService } from "src/app/core/session/session.service";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { Observable, Subscription } from "rxjs";
import * as moment from "moment";
import { SidebarComponent } from "@syncfusion/ej2-angular-navigations";

import { AppLocalStorage } from "src/app/utility/local-storage.util";
import { SnakbarService } from "../common/snakbar/snakbar.service";
import { SettingMenuDialogComponent } from "../modal/setting-menu-dialog/setting-menu-dialog.component";
//import { MyNotificationPayload } from "src/app/pages/notifications/service/my-notification.model";

@Component({
  selector: "app-topnav",
  templateUrl: "./topnav.component.html",
  styleUrls: ["./topnav.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class TopnavComponent implements OnInit, AfterViewInit, OnDestroy {
  isLoggedIn$: Observable<boolean>;

  isOpen: boolean = false; // open collection overlay.

  data: any;
  error: boolean;
  empty: boolean;
  loading: boolean;
  @ViewChild("toast") toast: ElementRef;
  productTooltipOpened: boolean;

  @ViewChild("sideNav")
  public sideNav: SidebarComponent;

  @ViewChild(SettingMenuDialogComponent) popUpModal: SettingMenuDialogComponent;

  logoPath: Observable<string>;
  FirmName: string;
  proRole: any;
  tabLinksLabel: any;
  firstUserLoggedIn: boolean = false;
  isUserGuideCompleted: boolean;
  notification: any = [];
  projectArray: any = [];
  projectCheckedArray: any = [];
  dates: any;
  user: any;
  isShow: boolean = true;
  filterText: any;
  accountDetails: any;
  queryString: string;
  receiverId: string;
  fromDate: string;
  toDate: string;
  userGuideText: any;
  pagination: {
    p: number;
    pp: number;
  };
  field: string;
  order: string;

  // selected='value';
  value;

  defaultnotificationData: any;

  NotificationData;
  newNotification: Observable<any>;
  NotificationId: string;
  PresentNotification: boolean;
  isDisabledOn = true;

  selectedDay: any = "30";

  fullName: Observable<string>;

  statusClass = "";

  notificationSubscriber: Subscription; //for socket
  userInfo: any;

  constructor(
    private snakbarService: SnakbarService,
    public session: SessionService,
    private router?: Router,
    private cd?: ChangeDetectorRef,
    private navigator?: Router
  ) {}

  ngOnDestroy(): void {
    const me = this;
    // me.notificationSubscriber.unsubscribe();
  }

  tabLinks: object[]; //TODO: add datatype for this

  settingTabLinks: object[];

  ngOnInit(): void {
    const me = this;
    //me.getProductValues();
    me.firstUserLoggedIn = me.session?.account?.hasUserLoggedInFirstTime;
    me.isUserGuideCompleted = me.session?.account?.isUserGuideCompleted;
    me.userInfo = AppLocalStorage.get("UserInfoByGoogle", "userInfo");

    me.tabLinks = [
      {
        label: "notifications",
        link: "/notifications",
      },
    ];

    me.logoPath = me.session.logoPath$;
    me.fullName = me.session.fullName$;
    // get notification
    var toDate = new Date();
    var fromdate = new Date().setDate(toDate.getDate() - 7);

    me.fromDate = moment(fromdate).startOf("day").format("YYYY-MM-DD HH:mm:SS");
    me.toDate = moment(toDate).endOf("day").format("YYYY-MM-DD HH:mm:SS");

    me.accountDetails = me.session?.account;
    me.receiverId = me.accountDetails?.id;
  }

  ngAfterViewInit() {
    const me = this;
  }

  // logout function
  // logOut() {
  //   const me = this;

  //   me.session.logout().subscribe(
  //     (state: Store.State) => {
  //       if (state instanceof LogoutLoadingState) {
  //         me.onLoading(state);
  //         return;
  //       }

  //       if (state instanceof LogoutLoadedState) {
  //         me.onLoaded(state);
  //         return;
  //       }
  //     },
  //     (state: LogoutErrorState) => {
  //       me.onLoadingError(state);
  //     }
  //   );
  // }

  // private onLoading(state: LogoutLoadingState) {
  //   const me = this;
  //   me.data = null;
  //   me.empty = false;
  //   me.error = null;
  //   me.loading = true;
  // }

  // private onLoadingError(state: LogoutErrorState) {
  //   const me = this;
  //   me.data = null;
  //   me.empty = false;
  //   me.error = true;
  //   me.loading = true;
  // }

  // private onLoaded(state: LogoutLoadedState) {
  //   const me = this;
  //   if (state.data?.errorCode == 0) {
  //     me.data = state.data;
  //     me.error = false;
  //     me.loading = false;
  //     if (me.data) {
  //       me.router.navigate(["logout"]);
  //     }
  //   }
  //   else{
  //     me.loading = false;
  //     me.snakbarService.showToast(me.toast.nativeElement, {
  //       // title: errorMessage.error?.error?.errorCode,
  //       title: state?.data?.message,
  //        type: "ERROR",
  //        showCloseButton: true,
  //      });
  //   }

  // }

  getClass(path: string) {
    // return window.location.pathname.substr(0, path.length) === path && !this.sideNav?.isOpen;
    return window.location.pathname.includes(path);
  }

  openSettingMenuDialog() {
    this.popUpModal.openDialog();
  }

  projectTooltipOutsideClick(event) {}

  onProjectChange(event, value: any) {}

  // getProductValues() {
  //   this.projectArray = [
  //     { value: "TRAQ", label: "Traq Workflow" },
  //     { value: "vuport", label: "Vuport" },
  //     { value: "Traq_inspection", label: "Traq Inspection" },
  //   ];
  // }
}
