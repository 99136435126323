<div class="control-section">
    <ejs-dialog #settingDialog [closeOnEscape]="true" [visible]="showModal" [animationSettings]='animationSettings'
        [target]='targetElement' [position]="position" [width]="confirmWidth">
        <ng-template #content>
            <!-- Profile Details Section START -->
            <!-- <div class="flex-column flex-container "> -->
                <div class="profile-info" >
                    <div class="initials-div flex-container flex-column">
                        <span class="initials">{{userInfo?.name | shortName:2}}</span>
                    </div>
                    <div class="flex-container flex-column align-start flex-wrap">
                        <p class="font-size-15 montserrat-Bold mv-10">{{userInfo?.firstName}} {{userInfo?.lastName}}</p>
                        <span>
                            <app-data-handler [size]="35" [content]="userInfo?.email" [fontSize]="13" [fontClass]="'open-sans-regular'">
                            </app-data-handler>
                        </span>
                    </div>
                </div> 
            <!-- </div>  -->
            <div class="middle-line">
            </div>
                <div class=" ph-20 flex-container flex-row justify-between p-20">
                    <div class="flex-container flex-column">
                        <img src="assets/custom/logout.png" width="25px"> 
                    </div>
                    <div class="flex-container flex-column  pr-80 flex-wrap">
                        <span class="open-sans-bold font-size-14 mv-5 pr-100 logout" (click)="logout()">Logout</span>
                        <ng-template #container></ng-template>
                    </div>
                </div> 
         

                <!-- <div class="flex-column flex-container">
                    <div class="profile-info ph-20 flex-container flex-row justify-between">
                      <div class="initials-div flex-container flex-column">
                        <span class="initials">{{userInfo?.name | shortName:2}}</span>
                      </div>
                      
                      New parent container for side-by-side layout -->
                      <!-- <div class="flex-container">
                        <div class="flex-container flex-column align-start flex-wrap">
                          <p class="font-size-15 montserrat-Bold mv-10">{{userInfo?.firstName}} {{userInfo?.lastName}}</p>
                          <span>
                            <app-data-handler [size]="35" [content]="userInfo?.email" [fontSize]="13" [fontClass]="'open-sans-regular'">
                            </app-data-handler>
                          </span>
                        </div>
                      </div>
                    </div>
                 

                    <!-- <div class="profile-link flex-item-width-100 flex-container flex-row justify-around align-center "  (click)="openLogout()">
                      <div class="flex-item-width-30 flex-container">
                        <img src="assets/custom/logout.png" width="25px"> 
                      </div>
                      <div class="flex-container align-start flex-item-width-70">
                        <span class="open-sans-bold font-size-14 mv-10">Logout</span>
                      </div>
                        <div fxLayout="row">
                            <ng-template #container></ng-template></div>
                    </div>   -->
                <!-- </div> -->
         




            <!-- <div class="profile-info ph-20" fxLayout="row" fxLayoutAlign="start center">
                 <div class="initials-div" fxLayout="column" fxLayoutAlign="start start" >
                    <span class="initials">{{userInfo?.name | shortName:2}}</span>
                </div> -->
                <!-- <div fxLayout="column" fxLayoutAlign="start start" fxFlex="70" fxFlexOffset="5">
                    <p class="font-size-15 montserrat-Bold mv-10">{{userInfo?.firstName}} {{userInfo?.lastName}}
                    </p>
                     <span>
                        <app-data-handler [size]="35" [content]="userInfo?.email" [fontSize]="13" [fontClass]="'open-sans-regular'">
                        </app-data-handler>
                    </span>
                </div>
            </div> --> 

            <!-- <div class="middle-line">
            </div> -->

            <!-- <div class="profile-info ph-20" fxLayout="row" fxLayoutAlign="start center"> -->
                <!-- <div class="initials-div" fxLayout="column" fxLayoutAlign="start start" fxFlex="20">
                    <span class="initials">{{userInfo?.name | shortName:2}}</span>
                </div> -->
                <!-- <div fxLayout="column" fxLayoutAlign="start start" fxFlex="70" fxFlexOffset="5">
                    <p class="font-size-15 montserrat-Bold mv-10">{{userInfo?.firstName}} {{userInfo?.lastName}}
                        <span>
                            <app-data-handler [size]="35" [content]="userInfo?.email" [fontSize]="13" [fontClass]="'open-sans-regular'">
                            </app-data-handler>
                        </span>
                    </p>
                  <p class="open-sans-regular font-size-13 mv-5">{{userDetails?.role}}</p> -->
                <!-- </div>  -->
            <!-- </div> -->
            <!-- Profile Details Section END -->

            <!-- Separator START -->
            <!-- <div class="middle-line">

            </div> -->
            <!-- Separator END -->
            <!-- Profile Modal Link Section START -->
            <div class="profile-links ph-20">
                <!-- <div class="profile-link" fxLayout="row" (click)="openProfile()">
                    <img src="assets/custom/account-profile.svg" width="25px">
                    <p class="open-sans-bold font-size-14 mv-10">My Info</p>
                </div>
                <div class="profile-link" fxLayout="row" (click)="openContact()">
                    <img src="assets/custom/noun-help-support.svg" width="25px">
                    <p class="open-sans-bold font-size-14 mv-10">Help & support</p>
                </div>


                <div class="profile-link" fxLayout="row" (click)="openAboutUs()">
                    <img src="assets/custom/noun-help-faq.svg" width="25px">
                    <p class="open-sans-bold font-size-14 mv-10">About Us</p>
                </div>

                <div class="profile-link" fxLayout="row" (click)="openFaq()">
                    <img src="assets/custom/noun-help-faq.svg" width="25px">
                    <p class="open-sans-bold font-size-14 mv-10">FAQ's</p>
                </div> -->
<!-- 
                <div class="profile-link" fxLayout="row" (click)="openLogout()">
                    <img src="assets/custom/logout.png" width="25px"> 
                    <span class="open-sans-bold font-size-14 mv-10">Logout</span>
                    <div fxLayout="row">
                        <ng-template #container></ng-template></div>
                </div>    -->
            </div>

            <!-- Profile Modal Link Section END -->
        </ng-template>
       
    </ejs-dialog>
    <div id="toast" #toast></div>

</div>
<app-logout-popup></app-logout-popup>