import { Toast, ToastModel } from '@syncfusion/ej2-notifications';
export interface CustomToastModel extends ToastModel {
  type?: 'ERROR' | 'WARNING' | 'INFO' | 'SUCCESS';
}

export enum SnakbarType {
  ERROR = 'e-toast-danger',
  WARNING = 'e-toast-warning',
  INFO = 'e-toast-info',
  SUCCESS = 'e-toast-success',
}

export enum SnakbarIcon {
  ERROR = 'e-danger toast-icons',
  WARNING = 'e-warning toast-icons',
  INFO = 'e-info toast-icons',
  SUCCESS = 'e-success toast-icons',
}
