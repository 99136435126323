import { MaterialModule } from "src/app/shared/material/material-module";
import { TopnavComponent } from "./topnav.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FlexLayoutModule } from "@angular/flex-layout";
import { NgxPermissionsModule } from "ngx-permissions";
import { SidebarModule } from "@syncfusion/ej2-angular-navigations";
import { SettingMenuDialogModule } from "../modal/setting-menu-dialog/setting-menu-dialog.module";
import { TooltipModule } from "@syncfusion/ej2-angular-popups";
import { CheckBoxModule } from "@syncfusion/ej2-angular-buttons";

TooltipModule;

const component = [TopnavComponent];

const imports = [
  CommonModule,
  RouterModule,
  MaterialModule,
  FlexLayoutModule,
  SidebarModule,
  SettingMenuDialogModule,
  NgxPermissionsModule.forChild(),
  TooltipModule,
  CheckBoxModule,
];
@NgModule({
  declarations: [component],
  exports: [component],
  imports: imports,
})
export class TopnavModule {}
