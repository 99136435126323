import { Component, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment-timezone';
import { SessionService } from './core/session/session.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {

  loggedIn = false;

  socket: WebSocket;

  constructor(public session: SessionService
  ) {
    window.moment = moment;
  }

  ngOnInit() {


  }

}
