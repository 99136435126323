import { NgModule } from '@angular/core';
import { DataSuffixPipe } from './data-suffix/data-suffix.pipe';
import { DatePipe, DateAgoPipe } from './date-pipe/date.pipe';
import { EllipsisPipe } from './ellipsis/ellipsis.pipe';
import { FileSizePipe } from './file-size-pipe/file-size.pipe';
import { MobileNumberPipe } from './mobile-number-pipe/mobile-number.pipe';
import { ProMobileNumberPipe } from './mobile-number-pipe/proMobile-number-pipe';
import { SortByPipe } from './sort-by/sort-by.pipe';
import { ShortNamePipe } from './short-name/short-name-pipe';

const pipes = [DatePipe, EllipsisPipe, FileSizePipe, DataSuffixPipe, DateAgoPipe, SortByPipe,MobileNumberPipe,ProMobileNumberPipe , ShortNamePipe];

@NgModule({
  declarations: [pipes],
  exports: [pipes],
})
export class PipesModule { }
