import { AfterContentInit, AfterViewInit, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { createSpinner, showSpinner } from '@syncfusion/ej2-angular-popups';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoadingComponent implements AfterViewInit {

  @Input() id: string;
  @Input() label: string;
  @Input() cssClass: string;
  @Input() template: any;
  @Input() type: any;
  @Input() width: string;
  constructor() { }

  ngAfterViewInit() {
    const me = this;
    //createSpinner() method is used to create spinner
    createSpinner({
      // Specify the target for the spinner to show
      target: document.getElementById(me.id ? me.id : 'spin-container'),

      label: me.label,
      cssClass: me.cssClass,
      template: me.template,
      type: me.type,
      width: me.width
    }
    );

    // showSpinner() will make the spinner visible
    showSpinner(document.getElementById(me.id ? me.id : 'spin-container'));
  }


}
