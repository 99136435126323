import { Store } from "src/app/core/store/store";
import { LicenseData } from "./license-management.model";

export class getDeploymentListLoadingState extends Store.AbstractLoadingState<any> {}
export class getDeploymentListLoadedState extends Store.AbstractIdealState<any> {}
export class getDeploymentListLoadingErrorState extends Store.AbstractErrorState<any> {}

export class createLicenseLoadingState extends Store.AbstractLoadingState<any> {}
export class createLicenseLoadedState extends Store.AbstractIdealState<any> {}
export class createLicenseLoadingErrorState extends Store.AbstractErrorState<any> {}

export class createNewClientLoadingState extends Store.AbstractLoadingState<any> {}
export class createNewClientLoadedState extends Store.AbstractIdealState<any> {}
export class createNewClientLoadingErrorState extends Store.AbstractErrorState<any> {}

export class logoutLoadingState extends Store.AbstractLoadingState<any> {}
export class logoutLoadedState extends Store.AbstractIdealState<any> {}
export class logoutLoadingErrorState extends Store.AbstractErrorState<any> {}

export class getClientListLoadingState extends Store.AbstractLoadingState<any> {}
export class getClientListLoadedState extends Store.AbstractIdealState<any> {}
export class getClientListLoadingErrorState extends Store.AbstractErrorState<any> {}

export class getPrimaryAdminListLoadingState extends Store.AbstractLoadingState<any> {}
export class getPrimaryAdminListLoadedState extends Store.AbstractIdealState<any> {}
export class getPrimaryAdminListLoadingErrorState extends Store.AbstractErrorState<any> {}

export class updateLicenseLoadingState extends Store.AbstractLoadingState<any> {}
export class updateLicenseLoadedState extends Store.AbstractIdealState<any> {}
export class updateLicenseLoadingErrorState extends Store.AbstractErrorState<any> {}