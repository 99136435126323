<div class="toppanel" fxLayout="row" fxFlex="100" fxLayoutAlign="start start">
  <div fxLayout="row" fxFlex="50" fxLayoutAlign="start start">
    <span fxLayout="column"> <img src="assets/custom/traq-white-logo.png" alt="GetMeFitLogo" width="40px">
    </span>
    <span class="project-name ml-10" fxLayout="column">
      TRAQ WORKFLOW
      <p fxLayout="row" fxLayoutAlign="start start" class="project-name">Cloud License Management</p>
    </span>

    <!-- <span fxLayout="column">
      <div id="target">
        <span>
          <ejs-tooltip #productTooltip target="#content" id="productTooltip" cssClass="e-tooltip-template-css"
            opensOn="Click" (afterClose)="productTooltipOpened = false">
            <div class="filter-icon cursor">
              <img src="assets/custom/arrow-down.png" alt="upper-arrow" width="18" id="content">

            </div>
            <ng-template #content class="Montserrat-Medium">
              <span Class="filter-checkbox Montserrat-Medium" (clickOutside)="projectTooltipOutsideClick($event)"
                appClickOutside>
                <form>
                  <div style="margin: 8px 0;" *ngFor="let item of projectArray">
                    <ejs-checkbox [name]="item.value" [value]="item.value" [label]="item.label "
                      (change)="onProjectChange($event, item.value)"
                      [checked]="projectCheckedArray.indexOf(item.value) > -1">
                    </ejs-checkbox>

                  </div>
                </form>
              </span> 
            </ng-template>
          </ejs-tooltip>
        </span> -->
      <!-- </div> -->
    <!-- </span> -->
  </div>
  <div fxLayout="column" fxFlex="80" fxLayoutAlign="end end">
    <div fxLayout="row" fxFlex="100" fxLayoutAlign="end end">
      <!-- <div class="menu-header-icon">
        <img src="assets/custom/home-icon.svg" alt="HomeIcon" width="17px">
      </div> -->

      <div class="menu-header-icon">
        <img src="{{userInfo?.photoUrl}}" alt="profileIcon" width="30px" height="30px" (click)="openSettingMenuDialog()">
      </div>
      <!-- <div class="menu-header-icon" (click)="openSettingMenuDialog()">
        <img src="assets/custom/Sign-out.png"  alt="logout" width="18px" height="18px">
      </div> -->
    </div>
  </div>
</div>
<app-setting-menu-dialog data="Hello" popUpTitle='Dailog Box'></app-setting-menu-dialog>
<div id="toast" #toast></div>