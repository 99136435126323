import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { SettingMenuDialogComponent } from './setting-menu-dialog.component';
import { PipesModule } from '../../pipes/pipes.module';
import { DataHandlerModule } from '../../data-handler/data-handler.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LogoutPopupComponent } from './logout-popup/logout-popup.component';
import { LogoutPopupModule } from './logout-popup/logout-popup.module';


@NgModule({
  declarations: [SettingMenuDialogComponent, ],
  imports: [
    CommonModule,
    DialogModule,
    PipesModule,
    DataHandlerModule,
    FlexLayoutModule,
    LogoutPopupModule
    
  ],
  exports: [SettingMenuDialogComponent]
})
export class SettingMenuDialogModule { }
