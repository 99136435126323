import { Store } from "src/app/core/store/store";

export const apis = {
  // websocket: {
  //   stomp: {
  //     endpoint: "http://10.10.10.218:8080/api/ws/notify/unread-count/",
  //   },
  // } as Store.APIConfigGroup,

  auth: {
    me: {
      endpoint: "/auth/me",
    },
    validate: {
      endpoint: "/auth/validate",
    },
    forgotPassword: {
      endpoint: "/auth/forgot-password",
    },
    verifyOtp: {
      endpoint: "/auth/otp/token",
    },
    verifyEmail: {
      endpoint: "/auth/verify-email",
    },
  } as Store.APIConfigGroup,

  resetPassword: {
    request: {
      endpoint: "/auth/reset-password-mailer",
    },
    verify: {
      endpoint: "/auth/reset-password-mailer",
    },
    reset: {
      endpoint: "/auth/reset-password",
    },
    signUp: {
      endpoint: "/user/register",
    },
  } as Store.APIConfigGroup,

  admin: {
    getUserDetails: {
      endpoint: "/getAllUsers_Collections",
    },
  } as Store.APIConfigGroup,

  session: {
    login: {
      endpoint: "/auth/login/google",
      // endpoint: "/auth/google",
    },
    logout: {
      endpoint: "/auth/logout",
    },

    verifyEmailForSignUp: {
      endpoint: "/user/verify-email",
    },

    token: {
      endpoint: "/auth/token",
    },
    tokenRefresh: {
      endpoint: "/token/refresh",
    },
    googleSignIn: {
      endpoint: "/auth/login/google",
    },
    facebookSignIn: {
      endpoint: "/auth/facebook",
    },
  },

  deploymentDashboard: {
    clientList: {
      endpoint: "/client/list",
    },
    createLicenseWithNewClient: {
      endpoint: "/client/create",
    },
    createLicense: {
      endpoint: "/cloud-license/add",
    },

    activateLicense: {
      endpoint: "/cloud-license/activate?id={licenseId}",
    },
    deactivateLicense: {
      endpoint: "/cloud-license/deactivate/{licenseId}",
    },
    updateUserDistribution: {
      endpoint: "/cloud-license/update",
    },
    licenseDetails: {
      endpoint: "/cloud-license/details",
    },
    licenseList: {
      endpoint: "/cloud-license/list",
    },
    forceSync: {
      endpoint: "/check-sync?licenseId={licenseId}",
    },

    checkPing: {
      endpoint: "/check-ping?licenseId={licenseId}",
    },
    expireWithGrace: {
      endpoint: "/cloud-license/expire-with-grace",
    },
    getCommunicationLogs: {
      endpoint: "/communication/logs/list",
    },

    getAllPrimaryAdmins: {
      endpoint: "/primary-admin/get-all?clientId={clientId}",
    },

    updateLicense : {
      endpoint: "/cloud-license/update"
    }
  } as Store.APIConfigGroup,
};
