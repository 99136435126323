import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { AnimationSettingsModel } from "@syncfusion/ej2-angular-popups";
import { SessionService } from "src/app/core/session/session.service";
import {
  LogoutErrorState,
  LogoutLoadedState,
  LogoutLoadingState,
} from "src/app/core/session/session.state";
import { LicenseDetailsService } from "src/app/pages/license-management-table/license-details/service/license-details.service";
import { LicenseManagementService } from "src/app/pages/license-management-table/service/license-management.service";
// import { deactivateLicenseLoadedState, deactivateLicenseLoadingErrorState } from 'src/app/pages/license-management-table/license-details/service/license-details.state';
import { SnakbarService } from "src/app/shared/common/snakbar/snakbar.service";
import { Router } from "@angular/router";
import { Store } from "src/app/core/store/store";
import { AppError } from "src/app/core/error/error.model";
import { SocialAuthService } from "@abacritt/angularx-social-login";
@Component({
  selector: "app-logout-popup",
  templateUrl: "./logout-popup.component.html",
  styleUrls: ["./logout-popup.component.scss"],
})
export class LogoutPopupComponent implements OnInit {
  empty: boolean;
  showModal: boolean = false;
  processing: boolean = false;
  headerText: string = "Logout  ";
  @ViewChild("toast") toast: ElementRef;
  animationSettings: AnimationSettingsModel = { effect: "None" };
  data: any;
  error: boolean;
  loading: boolean;

  onOpenPopup() {
    this.showModal = true;
    // throw new Error('Method not implemented.');
  }

  constructor(
    private snakbarService: SnakbarService,
    private sessionService: SessionService,
    private router: Router,
    private authService: SocialAuthService,
    private licenseDetailsService: LicenseManagementService
  ) {}

  ngOnInit(): void {}
  closeDialog() {
    this.showModal = false;
    this.removeDialogFromDOM();
  }

  removeDialogFromDOM() {
    setTimeout(() => {
      let createSessionModal: any = document.getElementById("logoutModal");
      createSessionModal && createSessionModal.remove();
    }, 10);
  }

  logout() {
    const me = this;
    this.showModal = false;
    this.processing = true;

    this.removeDialogFromDOM();

    //   (state: LogoutLoadedState) => {
    //     console.log("state", state)
    //     if (state instanceof LogoutLoadedState  ) {
    //       this.processing = false;
    //       me.router.navigate(["/login"]);

    //       me.snakbarService.showToast(me.toast.nativeElement, {
    //         title: "Logout successfully.",
    //         //  content: me.error?.msg,
    //         position: {
    //           X: "Right",
    //         },
    //         type: "SUCCESS",
    //         showCloseButton: true,
    //       });

    //       //  me.loading = false;
    //       //  me.resetPageNoAndData();
    //       // me.loadNotifications(me.getListConfig());
    //     } else {
    //       console.log("error");
    //     }
    //   },
    //   (state: LogoutErrorState) => {
    //     const error: any = state.error?.error;
    //     this.snakbarService.showToast(me.toast.nativeElement, {
    //       title:
    //         error?.message || `Something went wrong while creating license`,
    //       type: "ERROR",
    //     });
    //     return;
    //   }
    // );

    me.sessionService.logout().subscribe(
      (state: Store.State) => {
        if (state instanceof LogoutLoadingState) {
          me.onLoading(state);
          return;
        }

        if (state instanceof LogoutLoadedState) {
          me.onLoaded(state);
          return;
        }
      },
      (state: LogoutErrorState) => {
        me.onLoadingError(state);
      }
    );
  }

  private onLoading(state: LogoutLoadingState) {
    const me = this;
    me.data = null;
    me.empty = false;
    me.error = null;
    me.loading = true;
  }

  private onLoadingError(state: LogoutErrorState) {
    const me = this;
    me.data = null;
    me.empty = false;
    me.error = true;
    me.loading = true;
  }

  private onLoaded(state: LogoutLoadedState) {
    const me = this;
    this.authService.signOut(true).then((res)=>{
      this.router.navigate(["/login"]);
    });
    me.data = state.data;
    me.snakbarService.showToast(me.toast.nativeElement, {
      title: "Logged Out Successfully",
      position: {
        X: "Right",
      },
      type: "SUCCESS",
      showCloseButton: true,
    });

    // if (state.data?.errorCode == 0) {
    // me.data = state.data;
    // me.error = false;
    // me.loading = false;
    // me.router.navigate(["login"]);

    // if (me.data) {
    //   console.log("data", me.data)

    //   me.router.navigate(["login"]);

    // me.snakbarService.showToast(me.toast.nativeElement, {
    // title: 'Logged Out Successfully',
    // position: {
    // X: 'Right',
    // },
    // type: 'SUCCESS',
    // showCloseButton: true
    // });
    // }
    // me.router.navigate(["login"]);
    // }
    // else {
    // me.loading = false;
    // me.snakbarService.showToast(me.toast.nativeElement, {
    // title: state?.data?.message,
    // type: "ERROR",
    // showCloseButton: true,
    // });
    // }
  }
}
